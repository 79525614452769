import { useState, useEffect } from "react";
import { Button, Flex } from "@mantine/core";
import { getProductsPagination } from "../../modules";
import { Product, SkeletonLoader } from "../../components";
import { alert } from "../../utils";
import { IEntity } from "../../modules/main";

import Style from "../section.module.scss";

export default function AllProducts() {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<IEntity.SingleProduct[]>([]);
  const [nextPageUrl, setNextPageUrl] = useState<string | null>(null);

  useEffect(() => {
    getInitialProducts();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      loading
    ) {
      return;
    }
    loadMoreProducts();
  };

  const getInitialProducts = async () => {
    try {
      setLoading(true);
      const productsData = await getProductsPagination(
        "product/recent_products/"
      );
      if (productsData) {
        setProducts(productsData.results);
        setNextPageUrl(productsData.next);
      }
    } catch (error) {
      console.error(error);
      alert.error("Xatolik yuzaga keldi. Keyinroq urinib ko'ring!");
    } finally {
      setLoading(false);
    }
  };

  const loadMoreProducts = async () => {
    if (!nextPageUrl || loading) {
      return;
    }
    try {
      setLoading(true);
      const secureNextPageUrl = nextPageUrl.replace("http://", "https://");
      const moreProductsData = await getProductsPagination(secureNextPageUrl);
      if (moreProductsData) {
        setProducts((prevProducts) => [
          ...prevProducts,
          ...moreProductsData.results,
        ]);
        setNextPageUrl(moreProductsData.next);
      }
    } catch (error) {
      console.error(error);
      alert.error("Xatolik yuzaga keldi. Keyinroq urinib ko'ring!");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <p className={Style.productTitle}>Barcha Smartfonlar</p>
      {loading ? (
        <SkeletonLoader />
      ) : (
        <div className={Style.productsContainer}>
          {products.map((product) => (
            <Product key={product.id} product={product} />
          ))}
        </div>
      )}

      {nextPageUrl && (
        <Flex className={Style.moreButton}>
          <Button bg={"#fac73a"} onClick={loadMoreProducts} disabled={loading}>
            Ko'proq ko'rish
          </Button>
        </Flex>
      )}
    </div>
  );
}
